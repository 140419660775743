"use client";

import { useSession } from "next-auth/react";
import InfiniteScroll from "react-infinite-scroller";
import {
  ImageDeleteEventResponse,
  ImageGetRequest,
  ImageOrderCol,
  ImagePostEventResponse,
  ImageResponseData,
  OrderBy,
} from "@/restapi/image";
import { FC, useEffect, useState } from "react";

import useEcho from "@/hooks/useEcho";
import { useOrderByImages } from "@/hooks/image/useOrderByImages";
import { RANKING_TYPE, RankingTypeConst } from "@/restapi/ranking";
import FullScreenImage from "./FullScreenImage";
import { useImageFind } from "@/hooks/image/useImageFind";

/**
 * 全画面表示用のコンポーネント
 * @param param0
 * @returns
 */
const FullScreenList: FC<{
  selectedImageId: string;
  images: ImageResponseData[];
  imageOrderCol: ImageOrderCol;
  userUuid?: string;
  isNotScored?: boolean;
  orderBy: OrderBy;
  isFollwoing?: boolean;
  rankingType: RankingTypeConst;
}> = ({
  images,
  selectedImageId,
  imageOrderCol,
  orderBy,
  isNotScored,
  userUuid,
  rankingType,
  isFollwoing = false,
}) => {
    const { data: session } = useSession();
    const user = session?.user;
    const userId = user?.id ?? "";

    // 初期状態として渡された items をセット
    const [items, setItems] = useState<ImageResponseData[]>(images);

    const request: ImageGetRequest = {
      authUuid: userId,
      userUuid: userUuid ?? null,
      orderCol: imageOrderCol,
      orderBy,
      isNotScored: isNotScored ?? false,
      isFollwoing: isFollwoing ?? false,
    };

    const {
      items: _items,
      isError,
      isLoading,
      loadMore,
      hasMore,
      isValidating,
    } = useOrderByImages(request, items.length);

    const currentTime = new Date().toISOString();
    const { item: latestImage, isLoading: latestImageLoading, isError: latestImageError } = useImageFind({
      where: "created_at",
      whereCol: currentTime,
      operator: "=",
    })


    // 最新の投稿画像より投稿時刻が新しい場合は追加
    useEffect(() => {
      if (!latestImageLoading && !latestImageError && latestImage && 0 < _items.length) {
        const image = _items[0];
        // 最新か平均点なしの場合のみ追加
        if (latestImage.timestamp > image.timestamp
          && (rankingType === RANKING_TYPE.LATEST || rankingType === RANKING_TYPE.NOT_SCORED)) {
          setItems(pre => [latestImage, ...pre]);
        }
      }
    }, [latestImage]);


    // // 画像投稿時のリアルタイム反映処理
    // const echoPost = useEcho();
    // useEffect(() => {
    //   if (echoPost) {
    //     echoPost
    //       .channel(`channel-image-new-post`)
    //       .listen("ImageNewPostEvent", (response: ImagePostEventResponse) => {
    //         // 新規・フォロー中・スコアされてないリストのみ追加
    //         if (
    //           rankingType === RANKING_TYPE.NOT_SCORED ||
    //           rankingType === RANKING_TYPE.LATEST ||
    //           rankingType === RANKING_TYPE.FOLLOWING
    //         ) {
    //           // 画像投稿後にスコアされてない画像と新着投稿に追加
    //           const { image } = response;
    //             // 投稿画像とログインユーザーが同じ場合はスコアできない
    //             const newImage: ImageResponseData = {
    //               ...image,
    //               scoreExists: image.userUuid === userId,
    //             }

    //           setItems((pre) => [newImage, ...pre]);
    //         }
    //       });
    //   }
    // }, [echoPost]);

    // 画像削除時のリアルタイム反映処理
    // const echoDelete = useEcho();
    // useEffect(() => {
    //   if (echoDelete) {
    //     echoDelete
    //       .channel(`channel-image-delete`)
    //       .listen("ImageNewDeleteEvent", (response: ImageDeleteEventResponse) => {
    //         // 画像削除完了後に各画像一覧リストを更新
    //         const { imageId } = response;
    //         setItems((pre) => pre.filter((item) => item.uuid !== imageId));
    //       });
    //   }
    // }, [echoDelete]);

    // 遷移先で途中からスクロールを開始する場合の無限スクロールの配列にitemsを追加する処理
    useEffect(() => {
      if (!isLoading) {
        // 重複しないように新しいアイテムを追加
        setItems((prevItems) => {
          const newItems = _items.filter(
            (newItem) =>
              !prevItems.some((prevItem) => prevItem.uuid === newItem.uuid)
          );
          return [...prevItems, ...newItems];
        });
      }
    }, [_items.length]);

    // 選択された画像までスクロール
    useEffect(() => {
      if (selectedImageId && items.length > 0) {
        const targetIndex = items.findIndex(
          (img) => img.uuid === selectedImageId
        );
        if (targetIndex >= 0) {
          const obj = document.getElementById(`image-${targetIndex}`);
          if (obj) {
            obj.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }, []);

    if (isError) {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-black text-red-600 p-4">
          <div className="mb-10 text-center">Error</div>
        </div>
      );
    }

    return (
      <div className="pc-container bg-black min-h-screen">
        <div className="slide" id="scrollable-div">
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore && !isValidating}
            loader={
              <div key={0} className="loader">
                Loading ...
              </div>
            }
            useWindow={false}
          >
            {items.map((img, index) => (
              <div key={img.uuid} id={`image-${index}`} className="snap-item">
                <FullScreenImage image={img} />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  };

export default FullScreenList;
