"use client";

import useSWR from "swr";
import { ImageAPI, ImageFindRequest } from "@/restapi/image";
import APP_CONSTANTS from "@/constants/AppConstants";


const useImageFind = (request: ImageFindRequest) => {
  const { data: item, error } = useSWR(
    request.operator ? `find-${request.operator}-${request.where}` : null,
    () => ImageAPI.find(request),
    { refreshInterval: 5000 }
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};

export { useImageFind };
