import React, { FC, useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ImageDeleteEventResponse,
  ImagePostEventResponse,
  ImageResponseData,
} from "@/restapi/image";
import { RANKING_TYPE, RankingResponse, RankingTypeConst } from "@/restapi/ranking";
import { useTranslation } from "@/lib/react/i18n";
import useEcho from "@/hooks/useEcho";
import { useSession } from "next-auth/react";
import RankingImage from "./RankingImage";

type Props = {
  children?: React.ReactNode;
  defaultValue: string;
  clickkHighest?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  rankingType: RankingTypeConst;
  clickLowest?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  clickNotScored?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  clickLatest?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  clickFollowing?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;

  highestItems?: ImageResponseData[];
  lowestItems?: ImageResponseData[];
  notScoredItems?: ImageResponseData[];
  followingItems?: ImageResponseData[];
  latestItems?: ImageResponseData[];
  clickSelectImage: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    imageId: string
  ) => void;
  highestGetMore?: () => void | undefined;
  lowestGetMore?: () => void | undefined;
  notScoredGetMore?: () => void | undefined;
  latestGetMore?: () => void | undefined;
  followingGetMore?: () => void | undefined;
  highestHasMore?: boolean;
  lowestHasMore?: boolean;
  notScoredHasMore?: boolean;
  latestHasMore?: boolean;
  followingHasMore?: boolean;
};

const RankingList: FC<Props> = ({
  defaultValue,
  rankingType,
  clickkHighest = undefined,
  clickLowest = undefined,
  clickNotScored = undefined,
  clickLatest = undefined,
  clickFollowing = undefined,
  highestItems: _highestItems = undefined,
  lowestItems: _lowestItems = undefined,
  notScoredItems: _notScoredItems = undefined,
  followingItems: _followingItems = undefined,
  latestItems: _latestItems = undefined,
  clickSelectImage,
  latestGetMore = undefined,
  highestGetMore = undefined,
  notScoredGetMore = undefined,
  lowestGetMore = undefined,
  followingGetMore = undefined,
  highestHasMore = undefined,
  lowestHasMore = undefined,
  notScoredHasMore = undefined,
  latestHasMore = undefined,
  followingHasMore = undefined,
}) => {
  const { data: session } = useSession();
  const user = session?.user;
  const { t, loading } = useTranslation();
  const [highestItems, setHighestItems] = useState<ImageResponseData[]>(
    _highestItems ?? []
  );
  const [lowestItems, setLowestItems] = useState<ImageResponseData[]>(
    _lowestItems ?? []
  );
  const [notScoredItems, setNotScoredItems] = useState<ImageResponseData[]>(
    _notScoredItems ?? []
  );
  const [followingItems, setFollowingItems] = useState<ImageResponseData[]>(
    _followingItems ?? []
  );
  const [latestItems, setLatestItems] = useState<ImageResponseData[]>(
    _latestItems ?? []
  );

  useEffect(() => {
    if (_highestItems) {
      setHighestItems(_highestItems);
    }
  }, [_highestItems]);

  useEffect(() => {
    if (_lowestItems) {
      setLowestItems(_lowestItems);
    }
  }, [_lowestItems]);

  useEffect(() => {
    if (_notScoredItems) {
      setNotScoredItems(_notScoredItems);
    }
  }, [_notScoredItems]);

  useEffect(() => {
    if (_latestItems) {
      setLatestItems(_latestItems);
    }
  }, [_latestItems]);

  useEffect(() => {
    if (_followingItems) {
      setFollowingItems(_followingItems);
    }
  }, [_followingItems]);

  // 画像投稿時のリアルタイム反映処理
  // const echoPost = useEcho();
  // useEffect(() => {
  //   if (echoPost) {
  //     echoPost
  //       .channel(`channel-image-new-post`)
  //       .listen("ImageNewPostEvent", (response: ImagePostEventResponse) => {
  //         // 画像投稿後にスコアされてない画像と新着投稿に追加
  //         const { image } = response;
  //         setLatestItems([image, ...latestItems]);
  //         setNotScoredItems([image, ...notScoredItems]);
  //         setFollowingItems([image, ...followingItems]);
  //       });
  //   }
  // }, [echoPost]);

  // 画像削除時のリアルタイム反映処理
  // const echoDelete = useEcho();
  // useEffect(() => {
  //   if (echoDelete) {
  //     echoDelete
  //       .channel(`channel-image-delete`)
  //       .listen("ImageNewDeleteEvent", (response: ImageDeleteEventResponse) => {
  //         // 画像削除完了後に各画像一覧リストを更新
  //         const { imageId } = response;
  //         setLatestItems(latestItems.filter((item) => item.uuid !== imageId));
  //         setNotScoredItems(
  //           notScoredItems.filter((item) => item.uuid !== imageId)
  //         );
  //         setHighestItems(highestItems.filter((item) => item.uuid !== imageId));
  //         setLowestItems(lowestItems.filter((item) => item.uuid !== imageId));
  //         setFollowingItems(
  //           followingItems.filter((item) => item.uuid !== imageId)
  //         );
  //       });
  //   }
  // }, [echoDelete]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
        <div className="mb-10 text-center">Loading...</div>
      </div>
    );
  }

  return (
    <div className="mx-auto pc-container">
      <div className="bg-black min-h-screen">
        <Tabs className="rankListArea" defaultValue={defaultValue}>
          <TabsList className="rankListTxt">
            {clickkHighest && (
              <TabsTrigger
                value="Highest"
                onClick={clickkHighest}
                className={`${rankingType === RANKING_TYPE.HIGHEST ? "active" : ""
                  } data-[state=active]:text-white hover:text-white highestTxt`}
              >
                {t("Highest")}
              </TabsTrigger>
            )}
            {clickLowest && (
              <TabsTrigger
                onClick={clickLowest}
                value="Lowest"
                className={`${rankingType === RANKING_TYPE.LOWHEST ? "active" : ""
                  } data-[state=active]:text-white hover:text-white lowestTxt`}
              >
                {t("Lowest")}
              </TabsTrigger>
            )}
            {clickNotScored && (
              <TabsTrigger
                onClick={clickNotScored}
                value="NotScored"
                className={`data-[state=active]:text-white hover:text-white notScoredTxt`}
              >
                {t("Not Scored")}
              </TabsTrigger>
            )}
            {clickLatest && (
              <TabsTrigger
                onClick={clickLatest}
                value="Latest"
                className={`data-[state=active]:text-white hover:text-white notScoredTxt`}
              >
                {t("New")}
              </TabsTrigger>
            )}
            {clickFollowing && user && (
              <TabsTrigger
                value="Following"
                onClick={clickFollowing}
                className={`${rankingType === RANKING_TYPE.FOLLOWING ? "active" : ""
                  } data-[state=active]:text-white hover:text-white NotScoreTxt`}
              >
                {t("Following")}
              </TabsTrigger>
            )}
          </TabsList>
          {_highestItems && highestGetMore && (
            <TabsContent value="Highest">
              <InfiniteScroll
                key={`infinite-scroll-highest-${new Date().getTime()}`}
                dataLength={highestItems.length}
                next={highestGetMore}
                hasMore={highestHasMore ? highestHasMore : false}
                loader={<div>...loading</div>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {highestItems.map((item, index) => (
                    <RankingImage
                      onClick={clickSelectImage}
                      key={`${item.id}-${index}-high`}
                      image={item}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
          {_lowestItems && lowestGetMore && (
            <TabsContent value="Lowest">
              <InfiniteScroll
                key={`infinite-scroll-lowest-${new Date().getTime()}`}
                dataLength={lowestItems.length}
                next={lowestGetMore}
                hasMore={lowestHasMore ? lowestHasMore : false}
                loader={<div>...loading</div>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {lowestItems.map((item, index) => (
                    <RankingImage
                      onClick={clickSelectImage}
                      key={`${item.id}-${index}-low`}
                      image={item}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
          {_notScoredItems && notScoredGetMore && (
            <TabsContent value="NotScored">
              <InfiniteScroll
                key={`infinite-scroll-notScored-${new Date().getTime()}`}
                dataLength={notScoredItems.length}
                next={notScoredGetMore}
                hasMore={notScoredHasMore ? notScoredHasMore : false}
                loader={<></>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {notScoredItems.map((item, index) => (
                    <RankingImage
                      onClick={clickSelectImage}
                      key={`${item.id}-${index}-not-scored`}
                      image={item}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
          {_followingItems && followingGetMore && user && (
            <TabsContent value="Following">
              <InfiniteScroll
                key={`infinite-scroll-following-${new Date().getTime()}`}
                dataLength={followingItems.length}
                next={followingGetMore}
                hasMore={followingHasMore ? followingHasMore : false}
                loader={<div>...loading</div>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {followingItems.map((item, index) => (
                    <RankingImage
                      onClick={clickSelectImage}
                      key={`${item.id}-${index}-following`}
                      image={item}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
          {_latestItems && latestGetMore && (
            <TabsContent value="Latest">
              <InfiniteScroll
                key={`infinite-scroll-latest-${new Date().getTime()}`}
                dataLength={_latestItems.length}
                next={latestGetMore}
                hasMore={latestHasMore ? latestHasMore : false}
                loader={<div>...loading</div>}
              >
                <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4">
                  {latestItems.map((item, index) => (
                    <RankingImage
                      onClick={clickSelectImage}
                      key={`${item.id}-${index}-latest`}
                      image={item}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </TabsContent>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default RankingList;
