'use client';
import { BackendURLContantas } from "@/constants/URLConstants";
import { axios } from "@/lib/axios";

import { AxiosResponse } from "axios";
import { ImageFile, ImageRankingRequest, ImageResponseData } from "../image";
export const SCREEN_TYPE = {
  RANKING_TYPE: 1,
  TOP_TYPE: 2,
};

export const RANKING_TYPE = {
  HIGHEST: "Highest",
  LOWHEST: "Lowest",
  NOT_SCORED: "NotScored",
  LATEST: "Latest",
  FOLLOWING: "Following",
};

export type RankingTypeConst = (typeof RANKING_TYPE)[keyof typeof RANKING_TYPE];

export type RankingImageData = {
  id: number;
  userUuid: string;
  imageFileId: number;
  uuid: string;
  averageScore: number;
  city: string;
  country: string;
  province: string;
  suburb: string;
  latitude: number;
  longitude: number;
  timestamp: number;
  createdAt: string;
  updatedAt: string;
  imageFile: ImageFile;
};

export type RankingResponse = {
  data: ImageResponseData[];
  currentPage: number;
  lastPage: number;
  total: number;
};

// 画像ランキングのLaravel用API
export const RankingAPI = {
  NotScoredImages: async (request: ImageRankingRequest, page: number = 1) => {
    const { data } = await axios.post<
      ImageRankingRequest,
      AxiosResponse<RankingResponse>
    >(
      `${BackendURLContantas.IMAGE.RANKING.NOT_SCORED.PATH_NAME}?page=${page}`,
      request
    );

    return data;
  },

  lowestImages: async (request: ImageRankingRequest, page: number = 1) => {
    const { data } = await axios.post<
      ImageRankingRequest,
      AxiosResponse<RankingResponse>
    >(
      `${BackendURLContantas.IMAGE.RANKING.LOWEST.PATH_NAME}?page=${page}`,
      request
    );

    return data;
  },

  followingImages: async (request: ImageRankingRequest, page: number = 1) => {
    const endpointURL = `${BackendURLContantas.IMAGE.RANKING.FOLLOWING.PATH_NAME}?page=${page}`;

    const { data } = await axios.post<
      ImageRankingRequest,
      AxiosResponse<RankingResponse>
    >(endpointURL, request);

    return data;
  },

  highestImages: async (request: ImageRankingRequest, page: number = 1) => {
    const { data } = await axios.post<unknown, AxiosResponse<RankingResponse>>(
      `${BackendURLContantas.IMAGE.RANKING.HIGHEST.PATH_NAME}?page=${page}`,
      request
    );

    return data;
  },

  latestImages: async (request: ImageRankingRequest, page: number = 1) => {
    const { data } = await axios.post<
      ImageRankingRequest,
      AxiosResponse<RankingResponse>
    >(
      `${BackendURLContantas.IMAGE.RANKING.LATEST.PATH_NAME}?page=${page}`,
      request
    );

    return data;
  },
};
